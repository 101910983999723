import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Lazy load components
import Home from './Pages/Home';
const Privacy = lazy(() => import('./Pages/Privacy'));
const Quiz = lazy(() => import('./Pages/Quiz'));
const Subject = lazy(() => import('./Pages/Subject'));
const Pdfview = lazy(() => import('./Pages/Pdfview'));
const Videos = lazy(() => import('./Pages/Videos'));

export default function Mainroutes() {
    return (
        <BrowserRouter>
            <Suspense>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/home" element={<Home />} />
                    <Route exact path="/subject" element={<Subject />} />
                    <Route exact path="/quiz" element={<Quiz />} />
                    <Route exact path="/pdfview" element={<Pdfview />} />
                    <Route exact path="/videos" element={<Videos />} />
                    <Route exact path="/privacy" element={<Privacy />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}
